body {
  font: Oswald;
  background-color: var(--primary-background-color);
  --primary-text-color: #8D6B5F;
  --primary-background-color: #E8DCCE;
  --secondary-background-color: #663c2c;
  --logo-width: 142px;
  --menu-link-color: var(--primary-text-color);
  --menu-font-weight: 600;
  --menu-font-size: 20px;
  --menu-background-color: var(--primary-background-color);
  --menu-padding: 7px 28px;
  --menu-border-radius: 40px;
  --menu-border: 4px solid var(--primary-text-color); 
}
a {
  text-decoration: none;
}
.zoom-container.visible {
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom-container {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0,0,0,0.5);
}
.zoom-container img {
  margin:auto;
  display: block;
  max-height: 80%;
  max-width: 80%;
}
.ProductListSubCategory {
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
  width: calc(100% - 20px);
  color: var(--primary-text-color);
  font-size: 14px;
}
.ProductListCategory {
  text-align: left;
  margin-left: 10px;
  margin-top: 20px;
  width: calc(100% - 10px);
  color: var(--primary-text-color);
  font-weight: 500;
  font-size: 14px;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.Header {
  background-color: var(--secondary-background-color);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 150px;
  justify-content: center;
  justify-content: center;
  align-items: center;
}
.login {
  position: absolute;
  bottom: 0px;
  right: 7px;
  z-index: 1;
}
.Header .MuiIconButton-colorPrimary {
  color: var(--primary-background-color);
}
.Header .header-l1 {
  display: flex;
  z-index: 1;
}
.Header .Logo {
  height: auto;
  width: var(--logo-width);
  min-width: 100px;
  margin: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
  cursor: pointer;
}
.Header .Logo img {
  height: auto;
  width: 100%;
}
.header-left, .header-right {
  position: absolute;
  top: 0px;
  width: 600px;
  height: 150px;
  z-index: 0;
  max-width: 50%;
}
.header-left {
  left: 0;
  background-image: url('./assets/header-right.jpg');
  transform: rotate(180deg);
}
.header-right {
  right: 0;
  background-position: center;
  background-image: url('./assets/header-right.jpg');
}
.LanguageSelect {
  position: absolute;
  right: 21px;
  top: 21px;
  display: flex;
}
.LanguageChoice {
  margin-left: 10px;
  cursor: pointer;
  overflow: hidden;
  width: 34px;
  height: 17px;
}
.LanguageChoice img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.Menu {
  display: flex;
  background-color: var(--menu-background-color);
  min-width: 1145px;
  max-width: 1440px;
  margin-right: 15px;
  padding: var(--menu-padding);
  border-radius: var(--menu-border-radius);
  align-self: center;
  border: var(--menu-border);
  justify-content: space-around;
}
.Menu .MenuItem {
  margin: 0 10px;
  white-space: nowrap;
}
.Menu .MenuItem a {
  text-decoration: none;
  color: var(--menu-link-color);
  font-weight: var(--menu-font-weight);
  font-size: var(--menu-font-size);
  height: 27px;    
  display: flex;
  align-items: center;
}

.MobileMenuButton {
  position: absolute; 
  left: 7px;
  top: 4px;
}

.MobileMenu {
  min-width: 220px;
  background-color: var(--secondary-background-color);
}

.MobileMenu .Logo {
  display: flex;
  height: 145px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: var(--secondary-background-color);
}

.MobileMenu .MobileMenuItem {
  display: flex;
  height: 60px;
  align-items: center;
  padding-left: 35px;
  background-color: var(--secondary-background-color);
} 
.MobileMenu .MobileMenuItem a {
  text-decoration: none;
  color: var(--primary-background-color);
  font-weight: var(--menu-font-weight);
  font-size: var(--menu-font-size);
} 

.Page {
  align-self: center;
  max-width: 1440px;
  min-width: 320px;
  display: flex;
  width: 100%;
  flex-grow: 1;
  margin-top: 8px;
}

.Page>div {
  width: 100%;    
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.AdminRoot {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.ProductList, .CategoryList {
  width: 300px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 3px solid var(--primary-text-color);
  padding-top: 13px;
}
.ProductListTitle {
  width: 100%;
  font-weight: bold;
  padding-left: 30px;
  color: var(--primary-text-color);
}
.ProductListItem {
  height: 40px;
  width: calc(100% - 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--primary-text-color);
  margin: 4px 8px;
}
.ProductListItem[data-selected="true"] {
  font-weight: bold;
}
.AddImageLabel {
  margin-left: 16px;
}
.CustomizationCategoryItem .NameInput {
  width: calc(100% - 50px);
}
.CustomizationCategoryItem .actions {
  position: absolute;
  right: 0;
  top: 0;
}
.CustomizationCategoryItems {
  margin-left: -16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.CustomizationCategoryItemsTitle {
  font-weight: bold;
  color: var(--primary-text-color);
  font-size: 18px;
  margin-top: 16px;
  position: sticky;
  top: 62px;
  z-index: 8;
  background-color: var(--primary-background-color);
}
.CustomizationCategoryItem {
  min-width: 45%;
  border: 1px solid var(--primary-text-color);
  padding: 8px;
  border-radius: 8px;
  margin: 16px 0 16px 16px;
  position: relative;
}
.addProduct {
  position: absolute;
  top: 0px;
  right: 0px;
}
.Admin .category {
  width: 100%;
}
.Admin .CategoryList .category .name {
  cursor: pointer;
}
.Admin .category .name{
  font-weight: bold;
  color: var(--primary-background-color);
  text-transform: uppercase;
  font-size: 16px;
  background-color: var(--primary-text-color);
  padding: 4px;
  margin-top: 25px;
  display: flex;
}

.UpsertProduct {  
  padding-left:  16px;
  width: 850px;
  padding-bottom: 15px;
}
.UpsertProduct .title {
  position: sticky;
  top: 0px;
  background-color: var(--primary-background-color);
  z-index: 9;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-text-color);
  line-height: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
}
.UpsertProduct .CustomCategoryItem .title {
  position: unset;
}
.UpsertProduct .title .content {
  flex-grow: 1;
}

.NameInput {
  width: 400px;
  margin-right: 10px;
}
.PriceInput {
  width: 120px;
}
.SelectInput {
  margin-right: 9px;
  width: 295px;
}

.NameInput,
.SelectInput,
.PriceInput,
.ComponentsInput,
.imagesInput,
.DescriptionInput {
  margin: 20px 0 0 0;
}
.imagesInput {
  display: flex;
  align-items: center;
  max-width: 850px;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.ImageUpload {
  margin: 15px;
  min-width: 150px;
  min-height: 150px;
  display: flex;
  flex-direction: vertical;
  justify-content: center;
  align-items: center;
}
.ImageUpload.empty {
  min-height: unset;
  min-width: unset;
  margin: unset;
  margin-left: 15px;
}

.Flex {
  display: flex;
}
.SpaceBetween {
  justify-content: space-between;
}

.ImageUploadThumbnail {
  max-width: 150px;
  max-height: 150px;
  cursor: pointer;
}

.DisplayNone {
  display: none;
}


.Footer {
  background-color: var(--secondary-background-color);
  min-height: 135px;
  display: flex;
  flex-direction: row;
  width: 100%;
  color: var(--primary-background-color);
  overflow: hidden;
  justify-content: space-around;
  align-items: center;
}

.Footer .wrapper {
  max-width: 1440px;
  flex-wrap: wrap-reverse;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.Footer .Address {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Footer .Logo {
  margin-top: 6px;
}
.Footer .Logo,
.Footer .Logo img {
  height: 45px;
}
.Footer .Address .Title {
  font-size: 14px;
  font-weight: 400;
}
.Footer .AddressL1,
.Footer .AddressL2,
.Footer .AddressL3,
.Footer .AddressL4 {
  font-size: 12px;
  font-weight: 400;
}

.Footer .more-info a {
  text-decoration: none;
  color: var(--primary-background-color);
}
.Footer .more-info {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 600;
  font-size: 15px;
}

.Footer .Social {
  height: 118px;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.Footer .Social .fb-page {
  width: 230px;
}
.Footer .Social .Instagram {
  height: 40px;
  margin-bottom: 4px;
  overflow: hidden;
  display: inline-block;
  margin-left: 8px;
}
.Footer .Social .Instagram img {
  height: 100%;
}

.Category .Title {
  height: 300px;
  position: relative;
}

.Category .Title img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.Category .Title .Name {
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  background-color: rgba(232, 220,206, 0.7);
  padding: 0 7px;
  border-radius: 4px 0 0 4px;
  right: 0;
  top: calc(5%);
  color: var(--secondary-background-color);
}
.Category .Title .Hidder {
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 49%, var(--primary-background-color) 50%);
}
.Breadcrumb {
  font-weight: 500;
}
.Breadcrumb a {
  color: black;
}
.Breadcrumb a::after {
  content: " > ";
}
.fakeBreadcrumbLink {
  color: var(--primary-text-color);
  display: inline-block;
}

.Category .SubCategories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 20px;
}

.Category .SubCategoryItem {
  width: 170px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
.Category .SubCategoryItem img {
  display: inline-block;
  height: calc(100% - 30px);
  width: 100%;
  border-radius: 25px;
}
.Category .SubCategoryItem .Name {
  color: var(--primary-text-color);
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.ProductElementAlone .ProductAloneCarousel {
  margin: 30px 15px;
  height: auto;
  height: min(285px, 30vh);
  min-height: 150px;
}

.ProductElementAlone .ProductAloneCarousel .CarouselItem {
  width: 250px;
  display: flex;
  height: auto;
  height: min(250px, calc(30vh - 35px));
  min-height: 145px;
  justify-content: center;
  align-items: center;
}

.ProductElementAlone .Name {
  font-weight: 600;
  font-size: 32px;
  color: var(--secondary-background-color);
  text-align: center;
  margin: 15px 0;
}
.ProductElementAlone .Detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
  flex-wrap: wrap;
  justify-content: space-around;
}
.ProductElementAlone .Detail .Description {
  max-width: 800px;
  padding: 0 15px 15px 15px;
}
.ProductElementAlone .Detail .ComponentsAndPrice {
  max-width: 400px;
  padding: 0 15px 15px 15px;
}
.ProductElementAlone .Detail .Components {
  margin-bottom: 15px;
}
.ProductElementAlone .Detail .Components .label,
  .ProductElementAlone .Detail .Price .label {
    font-weight: 600;
    font-size: 20px;
  }
  .ProductElementAlone .Detail .Price .label {
    margin-right: 7px;
  }
  .ProductElementAlone .Detail .Price .value {
    margin-top: 1px;
  }
  
  .ProductElementAlone .Detail .Price {
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.SubCategoryAlone,
.SubCategory {
  color: var(--secondary-background-color);
}
.SubCategoryAlone .Title,
.SubCategory .Title {
  font-size: 50px;
  text-align: center;
}
.SubCategory .Content {
  display: flex;
}
.SubCategoryAlone .Content {
  padding: 7px;
}
.SubCategoryAlone .ChooseProduct {
  margin: 15px 0 25px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.SubCategoryAlone .ProductChoice {
  cursor: pointer;
  height: auto!important;
  margin-top:20px;
  color: var(--secondary-background-color);
}
.SubCategoryAlone .ProductChoice,
.SubCategory .ProductChoice {
  font-size: 22px;
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.SubCategoryAlone .ProductChoice img {
  margin-bottom: 8px;
}
.SubCategoryAlone .ProductChoice img,
.SubCategory .ProductChoice img {
  max-height: 175px;
  max-width: 175px;
}
.SubCategoryAlone .ScDescription,
.SubCategory .ScDescription {
  font-size: 20px;
  white-space: pre-line;
}
.ProductElement {
  margin-top: 15px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}
.ProductElement .Detail {
  /* max-width: 485px;
  width: 485px; */
}
.ProductElement .Detail .Price {
  display: flex;
  flex-direction: row;
}
.ProductElement .Detail .Price .value {
  font-weight: 500;
  margin-left: 8px;
}
.ProductElementAlone .Detail .Description,
.ProductElement .Detail .Description {
  white-space: pre-line;
}
.ProductElement .Detail .Name {
  font-weight: 600;
  font-size: 25px;
}
.ProductElement .Detail .Components {
  margin: 15px 0;
}
.ProductElement .Detail .Components .label {
  font-weight: 600;
  font-size: 20px;
}
.ProductElementAlone .Detail .Components .value,
.ProductElement .Detail .Components .value {
  white-space: pre-line;
}
.ProductElement .ProductCarousel {
  width: 435px;
  min-width: 435px;
  margin-top: 15px;
  margin-right:25px;
}
.ProductElement .ProductCarousel .CarouselItem {
  width: 300px;
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
}
.CarouselPlaceHolderItem {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.CarouselPlaceHolderItem img  {
  max-width: 300px;
  max-height: 300px;
}
.CarouselItem img {
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}
.rec-arrow:hover:enabled, 
.rec-arrow:focus:enabled  {
  background-color: var(--primary-text-color)!important;
}
.rec-dot_active {
  background-color: var(--primary-text-color)!important;
  box-shadow: 0 0 +1px 3px var(--primary-text-color)!important;
}
.rec-dot:hover {
  box-shadow: 0 0 +1px 3px var(--primary-text-color)!important;
}
#file-input {
  display: none;
}

.Contact {
  padding: 15px;
}

.Contact .Form .MuiTextField-root {
  margin: 15px 0;
}

.Contact .Title {
  font-size: 30px;
  font-weight: 600;
  color: var(--secondary-background-color);
  margin: 20px 0;
}
.Contact .SubTitle {
  margin-bottom: 15px;
}
.Contact .Contact-l1 {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
}
.Contact .Contact-l1 .MuiTextField-root {
  flex-grow: 1;
  display: flex;
  margin: 15px;
}
.Contact .Contact-action {
  display: flex;
  justify-content: center;
  padding: 15px 0px;
}

.Home-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Home-content .Column {
  width: 380px;
  max-width: calc(100vw - 30px);
  margin-bottom: 30px;
  padding: 15px 0;
}
.Home-content img {
  width: 100%;
}
.Home-content .Column .Title {
  font-size: 30px;
  font-weight: bold;
  color: var(--secondary-background-color);
  text-align: center;
  padding: 15px 8px;
}
.Home-content .Column .Text {
  font-size: 22px;
  font-weight: normal;
  color: var(--secondary-background-color);
}

.Home .Carousel {
  margin: 30px 15px;
  height: 235px;
  height: min(235px, 30vh);
  min-height: 150px;
}
.Home .CarouselItem {
  max-width: 300px;
  display: flex;
  height: min(200px, calc(30vh - 35px));
  min-height: 145px;
  justify-content: center;
  align-items: center;
}

.CustomizationsFront .Description {
  white-space: pre-line;
}
.CustomizationsFront>.Title {
  font-size: 30px;
  font-weight: 600;
  color: var(--secondary-background-color);
  margin: 8px 0;
  text-align: center;
  position: sticky;
  top: 0px;
  background-color: var(--primary-background-color);
  z-index: 6;
}


.CustomizationsFront .CustomizationCategory>.Title {
  margin: 8px 0;
  font-size: 24px;
  font-weight: 600;
  padding-left: 8px; 
  top: 35px;
  color: var(--secondary-background-color);
  position: sticky;
  background-color: var(--primary-background-color);
}
.CustomizationsFront .CustomizationCategory .Items {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.CustomizationsFront .CustomizationCategory .Items .Item {
  width: 350px;
  margin-bottom: 16px;
}
.CustomizationsFront .CustomizationCategory .Items .Item .Name {
  font-size: 20px;
  font-weight: 600;
  color: var(--secondary-background-color);
  text-align: center;
  margin-bottom: 4px;
}
.CustomizationsFront .CustomizationCategory .Items .Item .Description {
  white-space: pre-line;
  padding-left: 16px;
  margin-top: 8px;
  color: var(--secondary-background-color);
}
.CustomizationsFront .CustomizationCategory .Items .Image {
  overflow: hidden;
  border-radius: 16px;
}
.CustomizationsFront .CustomizationCategory .Items .Image img {
  width: 100%;
  height: auto;
}

.About {
  max-width: 1024px;
  margin-bottom: 24px;    
  margin-left: auto;
  margin-right: auto;
}
.About .banner {
  position: relative;
}
.About .banner img {
  max-width: 100%;
  transform: rotateY(180deg);
}
.About img {
  max-width: 100%;
}
.About .banner .Title {
  position: absolute;
  top: 260px;
  left: 50px;
  font-size: 40px;
  font-weight: bold;
  border-radius: 8px;
  padding: 0 4px;
  color: var(--secondary-background-color);
}
.About .Signature {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: right;
  font-weight: bold;
  color: var(--secondary-background-color);
}
.About .Text {
  color: var(--primary-text-color);
  padding: 8px 0;
}
.About .Content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px;
}
.About .Content img {
  margin-top: 8px;
  border-radius: 16px;
}
.About .Content .left {
  width: 500px;
  max-width: 100%;
}

.Repairs {
  max-width: 1024px;
  margin-bottom: 24px;    
  margin-left: auto;
  margin-right: auto;
}
.Repairs .banner {
  position: relative;
}
.Repairs .banner img {
  max-width: 100%;
}
.Repairs .banner .Title {
  position: absolute;
  top: calc( 50% - 27px);
  height: 52px;
  left: 24px;
  font-size: 40px;
  font-weight: bold;
  background-color: #F0F0F077;
  border-radius: 8px;
  padding: 0 4px;
  color: var(--secondary-background-color);
}

.Repairs .Text {
  color: var(--primary-text-color);
  margin-bottom: 8px;
}
.Repairs .Content {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Repairs .Content .Image {
  width: 415px;
  max-width: 100%;
}
.Repairs .Content .Image img {
  width: 415px;
  max-width: 100%;
  height: 275px;
  margin-bottom: 16px;
  border-radius: 16px;
}
.Repairs .Content .left {
  width: 415px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Repairs .Legend {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-top: -8px;
  margin-bottom: 8px;
  color: var(--secondary-background-color);
}

.HowToOrder {
  max-width: 1024px;
  margin-bottom: 24px;    
  margin-left: auto;
  margin-right: auto;
}
.HowToOrder > .Title {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: var(--secondary-background-color);

}
.HowToOrder .Content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 25px;
}
.HowToOrder .Content .Step {
  width: calc(50%);
  box-sizing: border-box;
  min-width: calc(50%);
  position: relative;
  color: var(--secondary-background-color);
  padding: 8px 50px;
  font-size: 18px;
}
.HowToOrder .Content .Step:nth-child(odd) {
  border-right: 4px solid var(--secondary-background-color);
}
.HowToOrder .Content .Step:nth-child(even) {
  border-left: 4px solid var(--secondary-background-color);
  margin-left: calc(50% - 4px);
}
.HowToOrder .Content .Title {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
}
.HowToOrder .Content .Number {
  position: absolute;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 12px;
  background: radial-gradient(var(--primary-background-color), var(--primary-background-color), transparent 90% );
  z-index: 5;
  width: 40px;
  text-align: center;
  border-radius: 100%;
  top: 40%;

}
.HowToOrder .Content .Step:nth-child(odd) .Number {
  right: -21px;
}
.HowToOrder .Content .Step:nth-child(even) .Number {
  left: -22px;
}

.HowToOrder .CGV {
  color: var(--secondary-background-color);
  text-align:center;
}
.HowToOrder a {
  color: var(--secondary-background-color);
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .header-right {
    display: none;
  }
  .header-left {
    max-width: 100%;
  }

  .About .banner .Title {
    top: 30%;
    left: 24px;
    background-color: #F0F0F077;
    border-radius: 8px;
    padding: 0 4px;
    color: var(--secondary-background-color);
  }
  
  .About .banner img {
    transform: rotateY(0deg);
  }
  .HowToOrder .Content {
    padding: 16px;
  }
  .HowToOrder .Content .Step {
    width: 100%;
  }
  .HowToOrder .Content .Step:nth-child(odd) .Number {
    right: auto;
    left: -22px;
  }
  .HowToOrder .Content .Step:nth-child(even) .Number {
    left: -22px;
  }
  .HowToOrder .Content .Step:nth-child(odd) {
    border-left: 4px solid var(--secondary-background-color);
    border-right: none;
  }
  .HowToOrder .Content .Step:nth-child(even) {
    border-left: 4px solid var(--secondary-background-color);
    margin-left: 0;
  } 
}